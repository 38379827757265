<template>
  <div class="footer">
    <div class="bg_101517">
      <div class="footer_nav">
        <div class="footer_nav_top clearfix">
          <div class="fl">
            <span class="col_fff f28 lh150">站点导航</span>
            <span class="col_999 f24 lh150">/Site navigation</span>
          </div>
          <ul class="footer-nav-box fr clearfix">
            <a href="/home">
              <li
                class="fl footer-nav-item f20 lh150"
                :class="{ 'footer-nav-item-active': activeitem === '/home' }"
              >
                首页
              </li>
            </a>

            <li
              class="fl footer-nav-item f20 lh150"
              :class="{
                'footer-nav-item-active':
                  activeitem === '/farmer' ||
                  activeitem === '/estate' ||
                  activeitem === '/cloudBill' ||
                  activeitem === '/book' ||
                  activeitem === '/rights',
              }"
            >
              产品服务
            </li>
            <a href="/recruit">
              <li
                class="fl footer-nav-item f20 lh150"
                :class="{ 'footer-nav-item-active': activeitem === '/recruit' }"
              >
                招聘信息
              </li>
            </a>
            <a href="/about">
              <li
                class="fl footer-nav-item f20 lh150"
                :class="{ 'footer-nav-item-active': activeitem === '/about' }"
              >
                联系我们
              </li>
            </a>
          </ul>
        </div>
        <div class="footer-nav_bottom clearfix">
          <div class="bottom-left fl clearfix">
            <div class="footer-2dcode bg_fff fl">
              <img src="@/assets/loader/catail-wx.jpg" alt="" />
            </div>
            <ul class="footer-desc tl fl f18">
              <li>
                <span>地址：</span>
                <span>山东省高新区舜华路街道齐鲁软件园C座A301室</span>
              </li>
              <li>
                <span>邮箱：</span>
                <span class="f24 col_fff">sales@catail.cn </span>
              </li>
              <li>
                <span>地址：</span>
                <span class="f24 col_fff">+86 0531-8881-2213</span>
              </li>
              <li>
                <span>在线：</span>
                <span class="f24 col_fff">8:30-17:30</span>
              </li>
            </ul>
          </div>
          <div class="bottom-right fr col_fff">
            <ul class="product-box df-sb">
              <a href="/rights">
                <li
                  class="product-box-item df-c csp"
                  :class="{ 'product-box-item-item': activeitem === '/rights' }"
                >
                  权益营销平台
                </li>
              </a>
              <a href="/cloudBill">
                <li
                  class="product-box-item df-c csp"
                  :class="{
                    'product-box-item-item': activeitem === '/cloudBill',
                  }"
                >
                  云账单
                </li>
              </a>
              <a href="/book">
                <li
                  class="product-box-item df-c csp"
                  :class="{ 'product-box-item-item': activeitem === '/book' }"
                >
                  书飞享
                </li>
              </a>
            </ul>
            <ul class="product-box df-sb">
              <a href="/estate">
                <li
                  class="product-box-item df-c csp"
                  :class="{ 'product-box-item-item': activeitem === '/estate' }"
                >
                  房地产智慧收款
                </li>
              </a>
              <a href="/farmer">
                <li
                  class="product-box-item df-c csp"
                  style="width: 340px"
                  :class="{ 'product-box-item-item': activeitem === '/farmer' }"
                >
                  农民工工资支付监管系统
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright f14 bg_2F2F38 col_fff">
      Copyright © 2023. 财兜科技 All rights reserved.
      <a href="https://beian.miit.gov.cn" target="_blank" title="点击查询"
        >鲁ICP备15035086号</a
      >
    </div>
  </div>
</template>

<script>
// import { ref, onMounted, onUnmounted } from "vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Footer",
  setup() {
    let activeitem = ref("");
    const router = useRouter();
    activeitem.value = router.currentRoute.value.path;
    // onMounted(() => {
    //   console.log();
    // });
    return {
      activeitem,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  .footer_nav {
    margin: 0 auto;
    width: 1200px;
    .footer_nav_top {
      height: 78px;
      padding-top: 18px;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
      .footer-nav-box {
        padding-top: 6px;
        .footer-nav-item {
          padding-left: 60px;
          color: rgba($color: #fff, $alpha: 0.5);
          &:hover {
            color: rgba($color: #fff, $alpha: 1);
          }
        }
        .footer-nav-item-active {
          color: rgba($color: #fff, $alpha: 1);
        }
      }
    }
    .footer-nav_bottom {
      padding-top: 46px;
      padding-bottom: 93px;
      // padding-right: 37px;
      .bottom-left {
        .footer-2dcode {
          margin-right: 37px;
          width: 137px;
          height: 137px;
          img {
            width: 100%;
          }
        }
        .footer-desc {
          li {
            padding-bottom: 6px;
          }
        }
      }
      .bottom-right {
        width: 520px;
        .product-box {
          padding-top: 21px;
          .product-box-item {
            width: 160px;
            height: 47px;
            border-radius: 4px;
            opacity: 1;
            color: #fff;
            border: 2px solid rgba(153, 153, 153, 0.5);
            background: rgba(47, 47, 56, 1);
            &:hover {
              border: none;
              background-color: #ff7a47;
            }
          }
          .product-box-item-item {
            border: none;
            background-color: #ff7a47;
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    line-height: 46px;
    a {
      color: #fff;
      &:hover {
        color: #add8e6;
      }
    }
  }
}
</style>
