<template>
  <nav class="" :class="{ changeOption: headerbj }">
    <CatailHeader />
  </nav>
  <main class="content">
    <!-- 二级路由 -->
    <router-view></router-view>
  </main>
  <footer>
    <CatailFooter />
  </footer>
</template>

<script>
import CatailHeader from "@/components/catailHeader.vue";
import CatailFooter from "@/components/catailFooter.vue";
import { ref, onMounted, onUnmounted } from "vue";
export default {
  name: "catail-layout",
  components: {
    CatailHeader,
    CatailFooter,
  },
  setup() {
    let headerbj = ref(false);
    const scrollTopNum = 78;
    const handleScroll = () => {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > scrollTopNum) {
        headerbj.value = true;
      }
      if (scrollTop <= scrollTopNum) {
        headerbj.value = false;
      }
    };
    onMounted(() => {
      window.addEventListener("scroll", handleScroll); //监听页面滚动
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    return {
      headerbj,
    };
  },
};
</script>

<style scoped lang="scss">
nav {
  // position: sticky; /* 新属性sticky */
  position: fixed; /* 新属性sticky */
  top: 0px; /* 距离页面顶部距离 */
  width: 100%;
  z-index: 999;
}
.changeOption {
  .header_bj {
    background-color: rgba($color: #fff, $alpha: 1);
  }
}
</style>
