import { createRouter, createWebHistory } from "vue-router";
import Layout from "@/Layout";
import HomeView from "@/views/homeView/index.vue";

const routes = [
  {
    path: "/", // 访问项目/服务器地址, 默认访问的都是/根路径
    component: Layout,
    redirect: "/home", // 路由重定向指向首页
    children: [
      {
        path: "/home", // 默认匹配的二级路由, 一级匹配后, 这个就直接生效在二级路由挂载点
        name: "home",
        component: HomeView,
      },
      {
        path: "/about",
        name: "about",
        component: () => import("@/views/aboutView/index.vue"),
      },
      {
        path: "/book",
        name: "book",
        component: () => import("@/views/bookView/index.vue"),
      },
      {
        path: "/cloudBill",
        name: "cloudBill",
        component: () => import("@/views/cloudBillView/index.vue"),
      },
      {
        path: "/estate",
        name: "estate",
        component: () => import("@/views/estateView/index.vue"),
      },
      {
        path: "/farmer",
        name: "farmer",
        component: () => import("@/views/farmerView/index.vue"),
      },
      {
        path: "/recruit",
        name: "recruit",
        component: () => import("@/views/recruitView/index.vue"),
      },
      {
        path: "/rights",
        name: "rights",
        component: () => import("@/views/rightsView/index.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  // { path: "/:pathMatch(.*)*", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
