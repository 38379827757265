<template>
  <div class="our_partners">
    <div class="heart">
      <div class="our_partners-header">
        <div class="header-title_en col_333">OUR PARTNERS</div>
        <div class="header-line"></div>
        <div class="header-title_zh col_E72D2C f40 fw7">合作伙伴</div>
      </div>
      <div class="our_partners-content">
        <ul class="clearfix box">
          <li
            v-for="num in 20"
            :key="num"
            class="fl item"
            :class="{ lastitem: num % 5 === 0 }"
          >
            <img
              :src="
                require('@/assets/loader/our_partners/our_partner' +
                  num +
                  '.webp')
              "
              alt=""
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.our_partners {
  padding-top: 197px;
  background: url("~@/assets/loader/our_partners-bj.webp") no-repeat bottom
    center;
  .our_partners-header {
    display: inline-block;
    padding: 0 93px;
    margin-bottom: 63px;
    background: url("~@/assets/loader/Catail-bj.webp") no-repeat;
    .header-title_en {
      font-size: 40px;
      font-weight: 700;
      line-height: 110%;
    }
    .header-line {
      display: inline-block;
      width: 60px;
      height: 2px;
      background: #ccc;
    }
    .header-title_zh {
      margin-top: 0;
    }
  }
  .our_partners-content {
    padding-bottom: 126px;
    .box {
      .item {
        width: 216px;
        height: 100px;
        background-color: #fff;
        margin-right: 30px;
        margin-bottom: 40px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .lastitem {
        margin-right: 0px;
      }
    }
  }
}
</style>
