<template>
  <!-- 一级路由 -->
  <router-view></router-view>
</template>

<script>
import { ref, watch, onMounted } from "vue";
export default {
  setup() {
    const windowWidth = ref(null);
    // 获取网页宽度缩放
    const getwindowwidth = () => {
      return document.documentElement.clientWidth; // 获取屏幕宽度
    };
    const pageZoom = () => {
      var ua = navigator.userAgent.toLowerCase();
      if (
        windowWidth.value < 1720 &&
        windowWidth.value > 780 &&
        ua.indexOf("firefox") == -1
      ) {
        document.documentElement.style.zoom = windowWidth.value / 1720;
      }
    };
    const clearzoom = () => {
      document.documentElement.style.zoom = "";
    };
    watch(windowWidth, () => {
      if (windowWidth.value < 1720 && windowWidth.value > 780) {
        pageZoom();
      } else if (windowWidth.value <= 780) {
        clearzoom();
      }
    });
    windowWidth.value = getwindowwidth();
    pageZoom();
    onMounted(() => {
      window.onresize = () => {
        windowWidth.value = getwindowwidth();
      };
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
