import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/index.scss"; // 全局样式
import "normalize.css";
// import ElementPlus from "element-plus";
// import "element-plus/dist/index.css";    .use(ElementPlus)

createApp(App).use(store).use(router).mount("#app");
