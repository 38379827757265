<template>
  <div class="home">
    <div class="banner">
      <el-carousel
        :interval="5000"
        arrow="always"
        height="800px"
        indicator-position="none"
      >
        <el-carousel-item v-for="item in 3" :key="item">
          <div class="item-box">
            <div class="heart banner-text">
              <div class="banner-text-title fw7 lh150">
                科技服务金融，乐享智慧生活
              </div>
              <div class="banner-text-line"></div>
              <div class="banner-text-desc f20 col_333 lh150">
                财兜科技是国内领先的金融信息服务、移动支付、互联网支付专业化产品提供商，
                为金融机构、企事业和消费者提供有竞争力的支付解决方案、产品和服务
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="heart pr">
        <ul class="banner-footer clearfix">
          <li class="fl banner-footer-item mg-r45 clearfix">
            <div class="fl footer-item-icon icon1"></div>
            <div class="fl footer-item-right">
              <div class="text-title">金融服务</div>
              <div class="text-desc">以科技创新、专业服务为经营理念</div>
            </div>
          </li>
          <li class="fl banner-footer-item mg-r45">
            <div class="fl footer-item-icon icon2"></div>
            <div class="fl footer-item-right">
              <div class="text-title">金融生活</div>
              <div class="text-desc">
                与银行及金融机构合作，提供优质产品服务
              </div>
            </div>
          </li>
          <li class="fl banner-footer-item">
            <div class="fl footer-item-icon icon3"></div>
            <div class="fl footer-item-right">
              <div class="text-title">科技助力</div>
              <div class="text-desc">
                实现流畅支付、资金高效运转促进互联网与传统行业融合
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="about_us heart">
      <div class="about_us-title">
        <div class="icon"></div>
        <div class="title-text lh150">关于我们</div>
        <div class="f16 col_d1d1d1 lh150">ABOUT US</div>
      </div>
      <div class="about_us-content">
        <div class="content-name f26 col_E72D2C lh150">
          山东财兜科技信息有限公司
        </div>
        <div class="content-year f20 col_E72D2C lh150">始于2015年</div>
        <div class="content-desc col_000 f16">
          财兜科技，公司总部位于美丽的泉城济南，是国内领先的金融信息服务、移动支付、互联网支付专业化产品提供商，专注于金融支付行业，坚持稳健经营、持续创新、开放合作的理念，在金融机构、企事业、终端和大数据等领域构筑端到端的解决方案，为金融机构客户、企事业客户和消费者提供有竞争力的金融支付解决方案、产品和服务。
        </div>
        <a style="display: inline-block" href="/about">
          <div class="content-toContact">
            <span class="f20">联系我们</span>
            <img src="@/assets/icon/home-about_us-right.png" alt="" />
          </div>
        </a>
        <div class="content-bj">
          <img src="@/assets/loader/home-about_us-bj.webp" alt="" />
        </div>
      </div>
    </div>
    <div class="product_center">
      <div class="heart">
        <div class="title-en col_333">PRODUCT CENTER</div>
        <div class="title col_E72D2C">产品中心</div>
        <div class="product_center-content">
          <ul class="clearfix">
            <a href="/rights">
              <li class="content-item fl mg-r30">
                <div class="item-icon icon1"></div>
                <div class="item-title f20 lh150">权益营销平台</div>
                <div class="item-line"></div>
                <div class="item-desc f16 lh150">
                  提供了一套客户分析、营销工具，可以看得到、找的着用户，可以与用户及时沟通
                </div>
              </li>
            </a>
            <a href="/cloudBill">
              <li class="content-item fl mg-r30">
                <div class="item-icon icon2"></div>
                <div class="item-title f20 lh150">云账单</div>
                <div class="item-line"></div>
                <div class="item-desc f16 lh150">
                  专为企事业客户提供的收费解决方案，具有多种渠道的支付，可以使用银行卡、微信支付
                </div>
              </li>
            </a>
            <a href="/estate">
              <li class="content-item fl mg-r30">
                <div class="item-icon icon3"></div>
                <div class="item-title f20 lh150">房地产智慧收款</div>
                <div class="item-line"></div>
                <div class="item-desc f16 lh150">
                  房地产智慧收款系统是为房地产企业提供的一套集房产销售、收款、财务统计、预售监管于一体的行业资金管理服务平台
                </div>
              </li>
            </a>
            <a href="/farmer">
              <li class="content-item fl mg-r30">
                <div class="item-icon icon4"></div>
                <div class="item-title f20 lh150">农民工工资支付监管</div>
                <div class="item-line"></div>
                <div class="item-desc f16 lh150">
                  专为企事业客户提供的收费解决方案，具有多种渠道的支付能力用户可以使用银行卡、微信支付
                </div>
              </li>
            </a>
            <a href="/book">
              <li class="content-item fl">
                <div class="item-icon icon5"></div>
                <div class="item-title f20 lh150">书飞享</div>
                <div class="item-line"></div>
                <div class="item-desc f16 lh150">
                  一款基于互联网+的图书共享阅读服务平台
                  ，利用信息化手段和智能自助设备，打通了校园内读者与校内图书馆、公共图书馆、社会化图书资源之间的通道
                </div>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
    <OurPartners />
  </div>
</template>

<script>
import OurPartners from "@/components/ourPartners.vue";
import { ElCarousel, ElCarouselItem } from "element-plus";
import "element-plus/theme-chalk/el-carousel.css";
import "element-plus/theme-chalk/el-carousel-item.css";
import "element-plus/theme-chalk/base.css";
export default {
  name: "HomeView",
  components: {
    OurPartners,
    ElCarousel,
    ElCarouselItem,
  },
};
</script>

<style lang="scss">
.home {
  .banner {
    .el-carousel {
      .el-carousel__arrow {
        border-radius: 0;
        width: 80px;
        height: 168px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
          .el-icon {
            svg {
              color: #e72d2c;
            }
          }
        }
        .el-icon {
          width: 60px;
          height: 60px;
          svg {
            width: 60px;
            height: 60px;
            :hover {
              color: #e72d2c;
            }
          }
        }
      }
      .el-carousel__arrow--left {
        left: 0px;
      }
      .el-carousel__arrow--right {
        right: 0px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.home {
  // banner
  .banner {
    .el-carousel__item .item-box {
      width: 100%;
      height: 800px;
      background: url("~@/assets/loader/home-banner.webp") no-repeat center
        center;
      background-size: cover;
      text-align: left;
    }
    .banner-text {
      padding-top: 233px;
      .banner-text-title {
        font-size: 48px;
        margin-bottom: 32px;
      }
      .banner-text-line {
        width: 102px;
        height: 4px;
        background: #e72d2c;
        margin-bottom: 32px;
      }
      .banner-text-desc {
        width: 726px;
      }
    }
    .banner-footer {
      position: absolute;
      top: -65px;
      .mg-r45 {
        margin-right: 45px;
      }
      .banner-footer-item {
        width: 370px;
        height: 148.41px;
        border-radius: 6.04px;
        opacity: 1;
        background: #f6f6fa;
        padding: 36px 26px;
        .footer-item-icon {
          width: 76px;
          height: 76px;
          margin-right: 30px;
        }
        .footer-item-right {
          text-align: left;
          color: #333;
          .text-title {
            font-size: 26.43px;
            font-weight: 700;
          }
          .text-desc {
            width: 211.43px;
            font-size: 16px;
            font-weight: 300;
          }
        }
        .icon1 {
          background: url("~@/assets/icon/Finance-Services.webp");
        }
        .icon2 {
          background: url("~@/assets/icon/Finance-life.webp");
        }
        .icon3 {
          background: url("~@/assets/icon/Technology-helps.webp");
        }
        &:hover {
          background: #e72d2c;
          .footer-item-right {
            color: #fff;
          }
          .icon1 {
            background: url("~@/assets/icon/Finance-Services-hover.webp");
          }
          .icon2 {
            background: url("~@/assets/icon/Finance-life-hover.webp");
          }
          .icon3 {
            background: url("~@/assets/icon/Technology-helps-hover.webp");
          }
        }
      }
    }
  }
  // 关于我们
  .about_us {
    margin-top: 185px;
    text-align: left;
    .about_us-title {
      margin-bottom: 55px;
      .icon {
        width: 30px;
        height: 4px;
        background: #333;
        margin-bottom: 18px;
      }
      .title-text {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }
    .about_us-content {
      position: relative;
      padding-top: 22px;
      padding-left: 40px;
      padding-bottom: 66px;
      background: #fafafa;
      margin-bottom: 125px;
      .content-year {
        margin-bottom: 20px;
      }
      .content-desc {
        width: 714px;
        margin-bottom: 47px;
      }
      .content-toContact {
        width: 181px;
        height: 47px;
        border-radius: 4px;
        border: 1px solid #ce2200;
        padding-top: 10px;
        padding-left: 35px;
        vertical-align: middle;
        span {
          color: #ce2200;
          margin-right: 10px;
        }
      }
      .content-bj {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
  // 产品中心
  .product_center {
    // height: 668px;
    padding-top: 57px;
    padding-bottom: 84px;
    background: url("~@/assets/loader/product_center-bg.webp") no-repeat top
      center;
    .title-en {
      font-size: 40px;
      font-weight: 700;
      padding-bottom: 10px;
    }
    .title {
      font-size: 32px;
      font-weight: 700;
      padding-bottom: 80px;
    }
    .product_center-content {
      .mg-r30 {
        margin-right: 30px;
      }
      .content-item {
        // cursor: pointer;
        width: 216px;
        border-radius: 8px;
        opacity: 1;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        padding-top: 40px;
        .item-icon {
          margin: 0 auto;
          width: 76px;
          height: 76px;
          margin-bottom: 20px;
        }
        .icon1 {
          background: url("~@/assets/icon/product_center-icon1.webp");
        }
        .icon2 {
          background: url("~@/assets/icon/product_center-icon2.webp");
        }
        .icon3 {
          background: url("~@/assets/icon/product_center-icon3.webp");
        }
        .icon4 {
          background: url("~@/assets/icon/product_center-icon4.webp");
        }
        .icon5 {
          background: url("~@/assets/icon/product_center-icon5.webp");
        }
        .item-title {
          font-weight: 700;
          margin-bottom: 14px;
        }
        .item-line {
          margin: 0 auto;
          width: 60px;
          height: 2px;
          background: #cccccc;
          margin-bottom: 28px;
        }
        .item-desc {
          margin: 0 auto;
          margin-bottom: 40px;
          width: 168px;
          height: 72px;
          color: #000;
          font-weight: 300;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        &:hover {
          background: #fff;
          .icon1 {
            background: url("~@/assets/icon/product_center-icon1-hover.webp");
          }
          .icon2 {
            background: url("~@/assets/icon/product_center-icon2-hover.webp");
          }
          .icon3 {
            background: url("~@/assets/icon/product_center-icon3-hover.webp");
          }
          .icon4 {
            background: url("~@/assets/icon/product_center-icon4-hover.webp");
          }
          .icon5 {
            background: url("~@/assets/icon/product_center-icon5-hover.webp");
          }
          .item-title {
            color: #e72d2c;
          }
          .item-line {
            background: #ff7a47;
          }
        }
      }
    }
  }
}
</style>
