<template>
  <div class="header_bj bg_fff">
    <div class="header clearfix">
      <div class="fl">
        <router-link to="/">
          <img src="@/assets/loader/logo.webp" alt="" />
        </router-link>
      </div>
      <ul class="fr navber">
        <li class="fl item" :class="{ active_item: activeItem === '/home' }">
          <a href="/" class="link">
            <span class="nav_text col_333 f20">首页</span>
          </a>
        </li>
        <li
          class="fl item"
          :class="{
            active_item:
              activeItem === '/farmer' ||
              activeItem === '/estate' ||
              activeItem === '/cloudBill' ||
              activeItem === '/book' ||
              activeItem === '/rights',
          }"
        >
          <div class="link csp">
            <span class="nav_text col_333 f20">产品方案</span>
            <span class="icon dib"></span>
            <div class="product_postion drop_box">
              <ul>
                <li class="drop_item f18 lh200">
                  <a href="/rights">权益营销平台</a>
                </li>
                <li class="drop_item f18 lh200">
                  <a href="/cloudBill">云账单</a>
                </li>
                <li class="drop_item f18 lh200"><a href="/book">书飞享</a></li>
                <li class="drop_item f18 lh200">
                  <a href="/estate">房地产智慧收款</a>
                </li>
                <li class="drop_item f18 lh200">
                  <a href="/farmer">农民工工资支付监管平台</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="fl item" :class="{ active_item: activeItem === '/recruit' }">
          <a href="/recruit" class="link">
            <span class="nav_text col_333 f20">加入我们</span>
          </a>
        </li>
        <li class="fl item" :class="{ active_item: activeItem === '/about' }">
          <a href="/about" class="link">
            <span class="nav_text col_333 f20">关于我们</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Header",
  setup() {
    let activeItem = ref("/home");
    const router = useRouter();
    activeItem.value = router.currentRoute.value.path;
    return {
      activeItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.header_bj {
  background: rgba($color: #fff, $alpha: 0.5);
  .header {
    width: 1200px;
    height: 78px;
    margin: 0 auto;
    position: relative;
    .navber {
      display: flex;
      .item {
        margin: 4px 20px;
        .link {
          display: inline-block;
          width: 110px;
          height: 70px;
          .nav_text {
            line-height: 70px;
          }
          .icon {
            margin-left: 4px;
            width: 22px;
            height: 22px;
            background: url("~@/assets/icon/down_def.png");
          }
        }
        &:hover {
          .nav_text {
            border-bottom: 1px solid #333;
          }
          .icon {
            background: url("~@/assets/icon/up_def.png");
          }
          .drop_box {
            display: block;
          }
        }
      }
      .active_item {
        .link {
          .icon {
            background: url("~@/assets/icon/down_hover.png");
          }
          .nav_text {
            color: #ff7a47;
            border: none;
          }
        }
        &:hover {
          .nav_text {
            border-bottom: 1px solid #ff7a47;
          }
          .icon {
            background: url("~@/assets/icon/up_hover.png");
          }
          .drop_box {
            display: block;
          }
        }
      }
    }
    .product_postion {
      position: absolute;
      top: 74px;
      left: 742px;
    }
    .add_postion {
      position: absolute;
      top: 68px;
      left: 923px;
    }
    .drop_box {
      z-index: 99;
      text-align: left;
      display: none;
      // width: 120px;
      background-color: #fff;
      border-radius: 4px;
      a {
        display: block;
      }
      .drop_item {
        margin: 6px 0px;
        padding: 0 10px;
        color: #333;
        &:hover {
          background-color: #ff7a47;
          a {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
